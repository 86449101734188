export default {
  /**
   *
   * There is a slight issue in Vue (and other MVVM frameworks) where if you
   * set a property (a boolean for example) that is to trigger a reactive
   * update in the UI followed by some more load intensive work (like setting
   * a loading flag) the problem is the load intensive work blocks the main
   * thread and Vue won't update the UI to _show_ the loader immediately when
   * the work is being done. This allows for having those properties set right
   * away and you do the load intensive work in the `then()` method of the
   * returned promise ensure it happens _after_ the properties are set and
   * rendered.
   *
   * @param {Function} propertySettingCallback Runs immediately to allow for setting properties in Vue that will trigger it to update and render the view immediately followed by allowing run logic _after_ the proerties are set, but ensure they render immediately.
   */
  ensureViewIsUpdatedAndRenderedImmediately(propertySettingCallback) {
    const promiseToExecuteAfterImmediatelyUpdating = new Promise((resolve) => {
      setTimeout(resolve)
    })
    propertySettingCallback()

    return promiseToExecuteAfterImmediatelyUpdating
  },
  keepHighlightedElementInScrollView() {
    const listRef = this.$refs['search-list']

    if (listRef) {
      setTimeout(() => {
        const listElement = listRef.$el
        const highlightedElement = listElement
          ?.getElementsByClassName('v-list__tile--highlighted')[0]

        if (highlightedElement) {
          const scrollCompensation = highlightedElement.clientHeight * 1.5

          highlightedElement.scrollIntoView()
          listElement.scrollTop -= scrollCompensation
        }
      })
    }
  },
}
