<template>
  <v-card class="welcome-card pa-6 elevation-0">
    <v-card-title class="justify-center primary--text">
      Welcome to iAdOps
    </v-card-title>

    <v-card-subtitle class="text-center">
      Enter Your Network Name or ID
    </v-card-subtitle>

    <v-row class="justify-center pb-9 pt-3">
      <search-bar />
    </v-row>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import SearchBar from 'src/tools/components/search/SearchBar'

const { mapGetters, mapState } = createNamespacedHelpers('auth')

export default {
  name: 'WelcomeCard',
  components: {
    SearchBar,
  },
  data() {
    return {
      dismissed: null,
    }
  },
  computed: {
    ...mapGetters(['alert']),
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    goToDocs() {
      this.$router.push('/docs/releases')
      this.dismissFeaturePrompt()
    },
    async dismissFeaturePrompt() {
      this.dismissed = true

      const { user } = this
      const { email, personEmail, settings = {} } = user
      const alert = { dismissed: true }
      const doc = { settings: { ...settings, alert } }
      const docId = email || personEmail
      const data = { doc, docId }

      return this.$store.dispatch('auth/updateUserSettings', data)
    },
  },
}
</script>
