<template>
  <form
    role="search"
    aria-label="Networks"
  >
    <v-menu
      v-model="expanded"
      :content-class="searchContainerClass"
      offset-y
      :close-on-content-click="false"
      bottom
      allow-overflow
      nudge-bottom="6"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-text-field
            v-model="searchText"
            autocomplete="off"
            solo
            flat
            :label="searchLabel"
            hide-details
            prepend-inner-icon="search"
            :loading="isLoadingSearch"
            @keydown.up="onArrowUp()"
            @keydown.down="onArrowDown()"
            @input="searchItems(searchText)"
          />
        </div>
      </template>

      <v-card class="search-results">
        <v-card-text
          v-for="endpoint in searchEndpoints"
          :key="endpoint.reportKey"
          class="pa-1"
        >
          <v-list
            ref="search-list"
            subheader
            class="py-0 very-dense search-results-list"
          >
            <v-subheader class="search-header px-3">
              <div>
                {{ endpoint.name }}
              </div>

              <v-spacer />

              <div v-if="endpoint.error">
                {{ endpoint.error.code }} Error
              </div>

              <div
                v-else-if="endpoint.results"
                :class="`${endpoint.results.length ? 'cursor-pointer font-weight-bold primary--text' : 'coolgray--text'}`"
                @click="endpoint.results.length && toggleAllResults(endpoint.reportKey)"
              >
                {{ endpoint.results.length }} Results
              </div>
            </v-subheader>

            <v-list-item
              v-for="item in mySearchResults[endpoint.reportKey]"
              :key="`${endpoint.reportKey}-${item.reportId}-${item.subId || '*'}`"
              :aria-label="item.text"
              @click="selectSearchItem(item, endpoint)"
            >
              <v-list-item-content
                class="search-result pa-0"
              >
                <v-list-item-title
                  class="subtitle-2 pa-1"
                >
                  <span
                    v-for="(chunk, i) of item.chunks"
                    :key="i"
                    :class="chunk.isMatched ? 'font-weight-bold' : ''"
                  >{{ chunk.value }}</span>

                  <span v-if="!!item.chunks === false">{{ item.text }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider class="mt-3" />

        <v-card-text class="pa-0">
          <v-list>
            <v-list-item>
              <v-list-item-content
                class="search-result pa-0"
              >
                <v-list-item-title
                  class="subtitle-2 pa-1"
                >
                  <span>
                    <router-link to="/docs">User Docs</router-link>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>

    <span
      v-if="didLoadSearchFail && searchText"
      class="error-message"
    >
      <v-icon>error_outline</v-icon>
      Failed to search. Please try again later.
    </span>
  </form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import render from 'src/utils/render'

const { mapGetters: mapAuthGetters, mapState: mapAuthState } = createNamespacedHelpers('auth')
const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'SearchBar',
  data: () => ({
    searchText: '',
    expanded: false,
    ready: false,
    seeNetworks: false,
    seeAllResults: false,
  }),
  computed: {
    ...mapAuthGetters(['inDemoMode', 'isInternalUser', 'network']),
    ...mapAuthState({
      user: (state) => state.user,
    }),
    ...mapGetters(['reportKey', 'selectedSearch']),
    ...mapState({
      didLoadSearchFail: (state) => state.didLoadSearchFail,
      isLoadingSearch: (state) => state.isLoadingSearch,
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      searchResults: (state) => state.searchResults,
      subId: (state) => state.subId,
    }),
    mySearchResults() {
      const { searchResults: { endpoints: allowedEndpoints } } = this
      const endpointNames = Object.keys(allowedEndpoints)

      return endpointNames.reduce((endpoints, endpointName) => {
        const endpoint = allowedEndpoints[endpointName]
        endpoints[endpointName] = this.getSearchResults(endpoint)
        return endpoints
      }, {})
    },
    searchEndpoints() {
      return this.searchResults.endpoints
    },
    searchTotal() {
      return this.searchResults.total
    },
    searchLabel() {
      const {
        didLoadSearchFail,
        isLoadingSearch,
        selectedSearch,
      } = this

      if (!selectedSearch) return 'Search ...'

      const { reportName, text } = selectedSearch

      if (isLoadingSearch) return `Fetching ${reportName} for ${text} ...`

      if (didLoadSearchFail) return 'Try another search ...'

      return text
    },
    showAllResults: {
      get() {
        const { seeAllResults } = this

        if (seeAllResults) return seeAllResults

        const { isInternalUser, searchEndpoints } = this
        const searchEndpointNames = Object.keys(searchEndpoints)

        return searchEndpointNames.reduce((endpoints, endpointName) => {
          const show = !isInternalUser && ['discrepancy', 'yield', 'pacing'].includes(endpointName)

          endpoints[endpointName] = show

          return endpoints
        }, {})
      },
      set(newValue) {
        const { showAllResults } = this
        this.seeAllResults = { ...showAllResults, [newValue]: !showAllResults[newValue] }
      },
    },
    showResults() {
      const {
        didLoadSearchFail,
        isLoadingSearch,
        searchTotal,
        searchText,
      } = this
      const loading = isLoadingSearch === false
      const failed = didLoadSearchFail === false
      const results = searchTotal.results !== 0
      const haveSearchText = searchText.length > 0

      return loading && failed && haveSearchText && results
    },
    searchContainerClass() {
      const { menuClass, searchTotal, searchText } = this
      const results = searchTotal.results > 0
      const recentSearches = searchTotal.recent > 0
      const haveContent = results || recentSearches || searchText

      const baseClasses = 'search-results-container white'
      const menuClasses = menuClass ? ` ${menuClass}` : ''
      const resultClasses = haveContent ? '' : ' results-empty'
      const containerClass = `${baseClasses}${menuClasses}${resultClasses}`

      return containerClass
    },
  },
  watch: {
    didLoadSearchFail(newValue) {
      if (newValue) {
        this.expanded = false
      }
    },
    inDemoMode() {
      this.setup()
    },
  },
  created() {
    this.setup()
  },
  methods: {
    async setup() {
      const searchTerm = ''

      this.searchText = searchTerm
      this.expanded = false

      await this.$store.dispatch('tools/setupNav')

      await this.$store.dispatch('tools/setSearch', searchTerm)

      this.ready = true
    },
    onArrowUp() {
      render.keepHighlightedElementInScrollView.call(this)
    },
    onArrowDown() {
      render.keepHighlightedElementInScrollView.call(this)
    },
    isCurrentSelection(selection) {
      const { reportId, reportName, reportPath, subId } = selection
      const {
        reportId: currentReportId,
        reportName: currentReportName,
        reportPath: currentReportPath,
        subId: currentSubId,
      } = this
      return currentReportId === String(reportId)
          && currentReportName === reportName
          && currentReportPath === reportPath
          && (currentSubId === subId || (!subId && currentSubId === '*'))
    },
    selectSearchItem(selection, endpoint) {
      this.searchText = ''
      this.expanded = false

      if (this.isCurrentSelection(selection)) {
        const message = `<b>Current Report</b><br>${selection.text} ${endpoint.name}`

        this.$store.commit('tools/snackbar', true)
        this.$store.commit('tools/snack', { message, status: 'info' })
      } else {
        this.$store.dispatch('tools/selectSearchItem', selection)
      }
    },
    searchItems(searchTerm) {
      if (this.ready) {
        this.expanded = true
        this.$store.dispatch('tools/setSearch', searchTerm)
      }
    },
    getSearchResults(endpoint) {
      const { reportKey, results } = endpoint
      const { showAllResults } = this

      if (showAllResults[reportKey]) return results

      return results ? results.slice(0, 3) : []
    },
    toggleAllResults(endpointName) {
      this.showAllResults = endpointName
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.search-results-container {
  z-index: 200 !important;
  border-radius: 6px;
  box-shadow: 0 2px 6px 3px rgba(165, 177, 185, 0.3);
}

.search-header {
  text-transform: uppercase;
  color: $color-monochrome-coolgray !important;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 11px;
}

.search-result {
  &:hover {
    color: $color-monochrome-blue;
  }
}

.search-results {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 3ch);
}

.search-results-list {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: auto;
}

.recents-list {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

::v-deep.v-input__icon i {
  color: $color-monochrome-darkestblue;
}

@media screen and (max-width: $vuetify-md-max-size) {
  .search-results {
    max-height: calc(100vh - 9ch);
  }
}

.v-list-item {
  min-height: 36px;
}

</style>
